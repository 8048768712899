import styled, { keyframes } from 'styled-components';

import { getThemeColor, getThemeTransition, media } from '@utils/styled';

import CloseButton from '@common/components/CloseButton';
import Typography from '@common/components/Typography';

export const BARCODE_WIDTH = 320;

const closeButtonFadeIn = keyframes`
  0% {
    top: calc(50% + 5rem);
    opacity: 0;
  }

  80% {
    top: calc(50% + 5rem);
    opacity: 0;
  }

  100% {
    top: 50%;
    opacity: 1;
  }
`;

export const FlipVIPCardCloseButton = styled(CloseButton).attrs({ type: 'button' })`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.1);
  color: ${getThemeColor('grey.100')};
  transform: translate(12.7rem, -14.4rem);
  animation-fill-mode: forwards;

  &:hover,
  &:active,
  &:focus {
    border-color: transparent;
  }

  .modal-transition-enter-active &,
  .modal-transition-enter-done & {
    animation: ${closeButtonFadeIn} forwards ${getThemeTransition('', 'verySlow', 'default')};
    animation-fill-mode: forwards;
  }

  .modal-transition-exit-active &,
  .modal-transition-exit-done & {
    animation: ${closeButtonFadeIn} reverse ${getThemeTransition('', 'verySlow', 'default')};
    animation-fill-mode: forwards;
  }

  ${media.from400down`
    transform: translate(10.7rem, -12.3rem);
  `}
`;

export const CardLabel = styled.p`
  color: ${getThemeColor('grey.100')};
`;

export const CardConditions = styled.p`
  color: ${getThemeColor('grey.100')};

  &:last-of-type:not(:only-child) {
    border-top: 1px solid ${getThemeColor('grey.100')};
    padding-top: 1em;
  }
`;

export const AdditionalTitle = styled(Typography).attrs({
  variant: 'h5',
})`
  margin-bottom: 0;
  color: ${getThemeColor('grey.100')};
  font-weight: 500;
  text-align: center;
`;

export const AdditionalTitleWrapper = styled.div`
  min-height: 6rem;
  margin-bottom: 0;
  padding: 1rem 0;
  text-align: center;
`;

export const FlipCardWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: ${BARCODE_WIDTH}px;
  transform: translate(-50%, -50%);
`;
