import React from 'react';
import PropTypes from 'prop-types';

import Modal from '@common/components/Modal';
import FlipVipCard from '@common/components/FlipVipCard';

import {
  AdditionalTitle,
  AdditionalTitleWrapper,
  CardConditions,
  CardLabel,
  FlipCardWrapper,
  FlipVIPCardCloseButton,
} from './FlipVipCardModal.styled';

const FlipVipCardModal = ({
  bottomText,
  isOpen,
  onClose,
  topText,
  vipCardRef,
  additionalTitle,
}) => (
  <Modal
    onClose={onClose}
    isOpen={isOpen}
    withCloseButton={false}
    timeout={690}
    mountOnEnter
    unmountOnExit
    appear
    exit
    enter
  >
    {additionalTitle && (
      <AdditionalTitleWrapper>
        <AdditionalTitle>{additionalTitle}</AdditionalTitle>
      </AdditionalTitleWrapper>
    )}
    <FlipVIPCardCloseButton
      className="vip-card-close"
      onClick={onClose}
    />
    <FlipCardWrapper>
      <FlipVipCard
        isOpen={isOpen}
        vipCardRef={vipCardRef}
        onClose={onClose}
        shouldSlideIn
      />
    </FlipCardWrapper>
    {topText && <CardLabel>{topText}</CardLabel>}
    {bottomText && <CardConditions>{bottomText}</CardConditions>}
  </Modal>
);

FlipVipCardModal.propTypes = {
  bottomText: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  topText: PropTypes.string,
  vipCardRef: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    current: PropTypes.any,
  }),
  additionalTitle: PropTypes.string,
};

FlipVipCardModal.defaultProps = {
  bottomText: undefined,
  isOpen: false,
  topText: undefined,
  vipCardRef: undefined,
  additionalTitle: null,
};

export default FlipVipCardModal;
